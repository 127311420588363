import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { useDataProvider, useResourceContext } from 'react-admin';
import { useIsTemplateResource } from '@rc/admin/hooks';

export const useValidateName = (props = {}) => {
  const { siblingEnvironments, debounceSettings = { leading: false } } = props;
  const { getList } = useDataProvider();
  const resource = useResourceContext();
  const isTemplate = useIsTemplateResource();

  const validateAsync = useCallback(async (value, allValues) => {
    const { id, project, templateProject } = allValues;
    if (project || templateProject) {
      const { data: siblingEnvironments } = await getList(resource, {
        filter: isTemplate ? { templateProject } : { project },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'DESC' }
      });

      return validate(value, id, siblingEnvironments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateAsyncDebounce = useMemo(() => {
    return debounce(validateAsync, 1000, { leading: debounceSettings.leading });
  }, [validateAsync, debounceSettings.leading]);

  return {
    validateAsync,
    validateAsyncDebounce,
    validate: (value, allValues) =>
      validate(value, allValues?.id, siblingEnvironments)
  };
};

const validate = (value, id, siblingEnvironments) => {
  if (
    siblingEnvironments?.some(
      environment => environment.name === value && environment.id !== id
    )
  ) {
    return 'resources.environments.validation.name_already_taken';
  }
};
