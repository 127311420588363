import {
  EventStreamContentType,
  fetchEventSource
} from '@microsoft/fetch-event-source';
import { LOGS_PAGE_SIZE } from '@rc/admin/constants';
import { useAxios } from '@rc/utils/axios';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useLogs = () => {
  const abortControllerRef = useRef(null);
  const consumerGroupId = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaging, setIsPaging] = useState(false);
  const [logs, setLogs] = useState([]);

  const [, getTopic] = useAxios(
    {},
    {
      manual: true
    }
  );

  const subscribe = useCallback(
    async (record, filters, offset) => {
      const isUserPaging = offset === -1 && consumerGroupId.current;
      setIsPaging(isUserPaging);

      if (!isUserPaging) {
        setLogs([]);
        setIsLoading(true);
      }

      /**
       * @type {import('axios').AxiosRequestHeaders}
       */
      const headers = {
        'x-filters': JSON.stringify({
          filters
        }),
        'x-offset': offset,
        'x-page-size': LOGS_PAGE_SIZE
      };

      if (isUserPaging) {
        headers['x-consumer-group-id'] = consumerGroupId.current;
      }

      try {
        const { data: topicData } = await getTopic({
          url: `/api/environments/${record.originId}/logs`,
          headers
        });

        consumerGroupId.current = topicData?.consumerGroupId;

        if (topicData?.topic) {
          const { topic, token } = topicData;

          const url = new URL(
            document.getElementById('mercure-entrypoint').innerText,
            window.location.origin
          );
          url.searchParams.append('topic', topic);

          abortControllerRef.current = new AbortController();

          fetchEventSource(url.toString(), {
            credentials: 'omit',
            headers: {
              Authorization: 'Bearer ' + token
            },
            openWhenHidden: true,
            signal: abortControllerRef.current.signal,
            onopen: response => {
              if (
                response.ok &&
                response.headers.get('content-type') === EventStreamContentType
              ) {
                setIsRunning(true);
              }
            },
            onmessage: message => {
              try {
                const parsedData = JSON.parse(message.data);

                if (parsedData.data) {
                  const log = window.atob(parsedData.data);
                  setLogs(logs => [...logs, log]);

                  if (offset === -1) {
                    setIsPaging(false);
                  }
                }
              } catch (error) {
                console.log('Received message: ', message);
                console.error(error);
              }
            },
            onerror: error => {
              console.error(error);
              setIsRunning(false);
            }
          });
        }
      } catch (error) {
        console.error(error);
        consumerGroupId.current = null;
      }

      setIsLoading(false);
    },
    [getTopic]
  );

  const more = useCallback(
    (record, filters) => {
      // Subscribe from the previous stream
      abortControllerRef.current?.abort();
      abortControllerRef.current = null;

      subscribe(record, filters, -1);
    },
    [subscribe]
  );

  const unsubscribe = useCallback(() => {
    setIsRunning(false);
    consumerGroupId.current = null;
    abortControllerRef.current?.abort();
    abortControllerRef.current = null;
  }, []);

  useEffect(() => {
    // Run log stream automatically
    // if (!shouldRender) return;

    //  subscribe(record, filters);

    return unsubscribe;
  }, [unsubscribe]);

  // // Mock;
  // useEffect(() => {
  //   setInterval(() => {
  //     setTimeout(() => {
  //       const log = Array(100)
  //         .fill('Lorem ipsum content this is')
  //         .join('. ');
  //       setLogs(logs => [
  //         ...logs,
  //         log
  //           .split('. ')
  //           .slice(Math.random() * 10, Math.random() * 10)
  //           .join('. ')
  //       ]);
  //     }, Math.random() * 10);
  //   }, 500);
  //   setIsRunning(true);
  // }, []);

  const stop = useCallback(() => {
    if (isRunning) {
      unsubscribe();
    }
  }, [isRunning, unsubscribe]);

  const canShowMore =
    !isPaging && logs.length !== 0 && logs.length % LOGS_PAGE_SIZE === 0;

  return {
    logs,
    isPaging,
    isLoading,
    isRunning,
    canShowMore,
    start: subscribe,
    stop,
    more
  };
};
