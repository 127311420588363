import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Button,
  ResourceContextProvider,
  useGetList,
  useRecordContext,
  useGetResourceLabel,
  ListContextProvider,
  useTranslate
} from 'react-admin';
import {
  styled,
  CircularProgress,
  Stack,
  Typography,
  Toolbar
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { Empty } from '../list/Empty';

export const AsideReferenceList = props => {
  const {
    resource,
    referenceSource,
    title,
    children,
    canCreate,
    relation
  } = props;
  const t = useTranslate();
  const record = useRecordContext();
  const getResourceLabel = useGetResourceLabel();

  const { id, [`@type`]: type } = record || {};

  const { data = [], isLoading: isDataLoading, total } = useGetList(
    resource,
    {
      filter: { [referenceSource]: relation === 'oneToMany' ? id : [id] },
      pagination: {
        page: 1,
        perPage: 250
      }
    },
    { enabled: !!id }
  );

  const isLoading = !id || isDataLoading;

  return (
    <ListContextProvider
      value={{
        data,
        sort: { field: 'id', order: 'ASC' },
        page: 1,
        isLoading,
        total
      }}
    >
      <ResourceContextProvider value={resource}>
        <StyledStack
          direction={'column'}
          justifyContent={isLoading ? 'center' : undefined}
        >
          {!isLoading && (
            <Toolbar className={classes.toolbar}>
              <Typography variant={'h6'}>
                {title ? t(title) : getResourceLabel(resource, 2)}
              </Typography>
              {record && canCreate && (
                <div>
                  <Button
                    component={Link}
                    variant={'text'}
                    color={'primary'}
                    label={'Create'}
                    to={{
                      pathname: `/${resource}/create`,
                      search: `${type.charAt(0).toLowerCase() +
                        type.slice(1)}=${encodeURIComponent(id)}`
                    }}
                  >
                    <Add />
                  </Button>
                </div>
              )}
            </Toolbar>
          )}
          {!isLoading ? (
            data.length ? (
              children
            ) : (
              <Empty />
            )
          ) : (
            <Stack className={classes.loading}>
              <CircularProgress />
            </Stack>
          )}
        </StyledStack>
      </ResourceContextProvider>
    </ListContextProvider>
  );
};

const PREFIX = 'AsideReferenceList';

const classes = {
  list: `${PREFIX}-list`,
  toolbar: `${PREFIX}-toolbar`,
  loading: `${PREFIX}-loading`
};

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  minHeight: 120,
  [`& .${classes.list}`]: {
    width: '100%'
  },

  [`& .${classes.toolbar}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 0,
    minHeight: 48,
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[900]
  },

  [`& .${classes.loading}`]: {
    width: '100%',
    height: '100%',
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

AsideReferenceList.propTypes = {
  resource: PropTypes.string.isRequired,
  referenceSource: PropTypes.string.isRequired,
  title: PropTypes.string,
  canCreate: PropTypes.bool,
  relation: PropTypes.oneOf(['oneToMany', 'manyToMany'])
};

AsideReferenceList.defaultProps = {
  canCreate: true,
  relation: 'oneToMany'
};
