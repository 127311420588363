import React, { useCallback, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useAxios } from '@rc/utils/axios';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  styled
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUser } from '@rc/admin/hooks';
import { FieldInput } from '@rc/admin/components';
import { PREFETCH_PAGINATION, PREFETCH_SORT } from '@rc/admin/constants';

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const DeleteAccount = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const t = useTranslate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user, isLoading: isUserLoading } = useUser();

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger
  } = useForm({
    defaultValues: {
      fullname: ''
    }
  });

  const [, removeAccount] = useAxios(
    {
      url: `/api/users/${user?.originId}/remove-account`,
      method: 'DELETE'
    },
    {
      manual: true
    }
  );

  const submit = useCallback(
    async data => {
      // Temporarily disallow account deletion
      if (true) {
        setOpen(false);
        notify('pages.profile.delete_account.notification.delete_prevented', {
          type: 'error'
        });
        return;
      }

      setIsLoading(true);

      try {
        const response = await removeAccount({
          headers: {
            'X-Delete': data.fullname
          }
        });

        if (!isEmpty(response.data)) {
          notify(response.data, { type: 'error' });
          setIsLoading(false);
          return;
        }

        notify('pages.profile.delete_account.notification.delete_success', {
          type: 'warning'
        });
        window.localStorage.removeItem('auth');
        window.localStorage.removeItem('permissions');
        window.localStorage.removeItem('user');
        navigate('/login');
      } catch (error) {
        console.error(error);
      }
    },
    [navigate, notify, removeAccount]
  );

  return (
    <>
      <Button
        variant={'outlined'}
        color={'error'}
        onClick={() => setOpen(curr => !curr)}
      >
        {t('pages.profile.delete_account.action.delete')}
      </Button>
      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(!open)}
        scroll={'paper'}
        aria-describedby={'dialog-title'}
      >
        <DialogTitle id='dialog-title'>
          {t('pages.profile.delete_account.delete_title', {
            name: user?.fullname
          })}
        </DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          <DialogContentText>
            {t('pages.profile.delete_account.delete_confirmation')}
          </DialogContentText>
          <Box>
            <FieldInput
              name={'delete-account-fullname'}
              label={t('ra.auth.fullname')}
              errors={errors}
              control={control}
              required
              rules={{
                required: true,
                validate: value =>
                  value !== user?.fullname
                    ? 'pages.profile.delete_account.validation.values_does_not_match'
                    : undefined
              }}
              onBlur={() => trigger('fullname')}
              disabled={isLoading}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(!open)}>
            {t('ra.action.cancel')}
          </Button>
          <LoadingButton
            variant={'outlined'}
            color={'error'}
            onClick={handleSubmit(submit)}
            loading={isLoading || isUserLoading}
          >
            {t('ra.action.delete')}
          </LoadingButton>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

const PREFIX = 'DeleteAccount';

const classes = {
  submit: `${PREFIX}-submit`
};

const StyledDialog = styled(Dialog)(() => ({
  minWidth: 300,
  [`& .${classes.submit}`]: {
    textAlign: 'right'
  }
}));

export default DeleteAccount;
