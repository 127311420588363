import { useCallback } from 'react';
import { useAxios } from '@rc/utils/axios';
import { useNotify, useRecordContext } from 'react-admin';
import { STATE_IDS } from '@rc/admin/constants';
import { useStateId } from '@rc/admin/hooks';

const ENABLED_STATES = [
  STATE_IDS.PROGRESSING,
  STATE_IDS.PROGRESSED,
  STATE_IDS.READY,
  STATE_IDS.RUN
];

export const useBuildAndDeploy = () => {
  const record = useRecordContext();
  const stateId = useStateId();
  const notify = useNotify();

  const isEnabled = ENABLED_STATES.includes(stateId);

  const [{ loading: isLoading, error, data }, execute] = useAxios(
    {
      url: `${record['@id']}/build-and-deploy`,
      method: 'PATCH',
      headers: {
        Accept: 'application/merge-patch+json',
        'Content-Type': 'application/merge-patch+json'
      },
      data: record
        ? {
            '@id': record['@id'],
            '@type': record['@type'],
            id: record.id,
            uuid: record.uuid
          }
        : {}
    },
    { manual: true }
  );

  const executeBuildAndDeploy = useCallback(() => {
    if (isEnabled) {
      return execute()
        .then(() =>
          notify('Build and deploy started', { type: 'info', undoable: false })
        )
        .catch(error => {
          notify('Could not run build and deploy.', {
            type: 'error',
            undoable: false
          });
          console.error(error);
        });
    }

    throw new Error('Build and deploy is not enabled');
  }, [execute, isEnabled, notify]);

  return {
    execute: executeBuildAndDeploy,
    isLoading,
    error,
    data,
    isEnabled
  };
};
