import { useGetList } from 'react-admin';
import { useRequiredComponents } from './useRequiredComponents';

export const getComponentDefaultVersion =
  defaultComponentVersions => component => {
    return (
      component.componentVersion.find(componentVersion =>
        defaultComponentVersions.includes(componentVersion)
      ) || component.componentVersion[component.componentVersion.length - 1]
    );
  };

export const useDefaultComponentVersions = props => {
  const { sort, pagination } = props;
  const {
    data: components = [],
    isLoading: isComponentsLoading,
    isFetching: isComponentsFetching
  } = useGetList('components', { sort, pagination });

  const {
    requiredComponents,
    defaultComponentVersions,
    isLoading: isRequiredComponentsLoading,
    isFetching: isRequiredComponentsFetching
  } = useRequiredComponents();

  const defaultVersions = components
    .filter(component => requiredComponents.includes(component.id))
    .map(getComponentDefaultVersion(defaultComponentVersions));

  return {
    defaultComponentVersions: defaultVersions,
    isLoading: isComponentsLoading || isRequiredComponentsLoading,
    isFetching: isComponentsFetching || isRequiredComponentsFetching
  };
};
