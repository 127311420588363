import React, { useCallback, useMemo } from 'react';
import {
  Create,
  useDataProvider,
  useGetList,
  useNotify,
  useRedirect,
  useTranslate
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  styled,
  Typography
} from '@mui/material';
import {
  DEFAULT_PROJECT_COLOR,
  INTERNAL_GIT_TYPE_ID,
  PREFETCH_PAGINATION,
  PREFETCH_SORT
} from '@rc/admin/constants';
import {
  WizardForm,
  WizardFormStep,
  useFormStepperContext,
  WizardFormToolbar
} from '@rc/admin/components';
import { useOrgContext } from '@rc/admin/context';
import { CREATE_METHOD_TYPES, transformData } from './utils';
import {
  ProjectCreateStepChooseTemplate,
  ProjectCreateStepDeploy,
  ProjectCreateStepDetails,
  ProjectCreateStepResources,
  ProjectCreateStepStack,
  ProjectCreateStepVariables
} from './steps';
// import { StackStepIconComponent } from './StackStepIconComponent';
import {
  getComponentFromComponentVersion,
  getProjectTypeForProjectTypeVersion
} from '@rc/admin/utils';
import { useWatch } from 'react-hook-form';

/**
 *
 * @param {object} props
 * @param {"template"|"scratch"} props.method
 * @returns
 */
export const ProjectCreate = props => {
  const { method } = props;
  const t = useTranslate()
  const { organisation, team } = useOrgContext();

  const isAllowedToCreate =
    organisation?.isConfirmedByInfra && team?.isConfirmedByInfra;

  const submit = useProjectCreateSubmit({ method });

  if (!team?.id) {
    return null;
  } else if (!isAllowedToCreate) {
    return (
      <Stack width={'100%'}>
        <Card>
          <CardContent
            sx={theme => ({
              width: '100%',
              minHeight: '80vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyItems: 'center',
              marginTop: '20%',

              [theme.breakpoints.up('md')]: {
                padding: `${theme.spacing(4)} ${theme.spacing(4)}`
              }
            })}
          >
            <Typography variant='subtitle1' component={'p'}>
              {t('resources.projects.message.create_not_allowed')}
            </Typography>
            <LinearProgress
              sx={theme => ({
                maxWidth: 400,
                width: '100%',
                marginTop: theme.spacing(2)
              })}
            />
          </CardContent>
        </Card>
      </Stack>
    );
  }

  return (
    <Create transform={transformData}>
      <WizardForm
        defaultValues={{
          team: team?.id,
          isActive: true,
          color: DEFAULT_PROJECT_COLOR,
          git: {
            gitType: INTERNAL_GIT_TYPE_ID
            // credentialType: HTTP_GIT_CREDENTIAL_TYPE_ID
          },
          projectEnvVar: []
        }}
        onSubmit={submit}
        toolbar={<Toolbar />}
      >
        {method === CREATE_METHOD_TYPES.FROM_TEMPLATE && (
          <WizardFormStep
            label='resources.projects.tabs.choose_template'
            showToolbar={false}
          >
            <ProjectCreateStepChooseTemplate {...props} />
          </WizardFormStep>
        )}
        <WizardFormStep
          label={
            method === CREATE_METHOD_TYPES.FROM_SCRATCH
              ? 'resources.projects.tabs.details'
              : 'resources.projects.tabs.details_template'
          }
          showToolbar={method === CREATE_METHOD_TYPES.FROM_SCRATCH}
          forceSubmit={method === CREATE_METHOD_TYPES.FROM_TEMPLATE}
        >
          <ProjectCreateStepDetails {...props} />
        </WizardFormStep>
        {method === CREATE_METHOD_TYPES.FROM_SCRATCH && (
          <WizardFormStep
            label='resources.projects.tabs.stack'
            // StepIconComponent={StackStepIconComponent}
          >
            <ProjectCreateStepStack {...props} />
          </WizardFormStep>
        )}
        {method === CREATE_METHOD_TYPES.FROM_SCRATCH && (
          <WizardFormStep label='resources.projects.tabs.resources'>
            <ProjectCreateStepResources {...props} />
          </WizardFormStep>
        )}
        {method === CREATE_METHOD_TYPES.FROM_SCRATCH && (
          <WizardFormStep label='resources.projects.tabs.variables'>
            <ProjectCreateStepVariables {...props} />
          </WizardFormStep>
        )}
        {method === CREATE_METHOD_TYPES.FROM_TEMPLATE && (
          <WizardFormStep
            label='resources.projects.tabs.deploy'
            showToolbar={false}
          >
            <ProjectCreateStepDeploy {...props} />
          </WizardFormStep>
        )}
      </WizardForm>
    </Create>
  );
};

const Toolbar = props => {
  const [{ activeStep }] = useFormStepperContext();
  const [projectTypeVersionId, componentVersionIds] = useWatch({
    name: ['projectTypeVersion', 'componentVersion']
  });

  const { data: projectTypes } = useGetList('project_types', {
    sort: PREFETCH_SORT,
    pagination: PREFETCH_PAGINATION
  });
  const { data: projectTypeVersions } = useGetList('project_type_versions', {
    sort: PREFETCH_SORT,
    pagination: PREFETCH_PAGINATION
  });
  const { data: components } = useGetList('components', {
    sort: PREFETCH_SORT,
    pagination: PREFETCH_PAGINATION
  });
  const { data: componentVersions } = useGetList('component_versions', {
    sort: PREFETCH_SORT,
    pagination: PREFETCH_PAGINATION
  });

  const projectType = useMemo(
    () =>
      getProjectTypeForProjectTypeVersion(
        projectTypes,
        projectTypeVersions,
        projectTypeVersionId
      ),
    [projectTypeVersionId, projectTypeVersions, projectTypes]
  );

  const componentsList = useMemo(
    () =>
      componentVersionIds?.map(componentVersionId =>
        getComponentFromComponentVersion(
          components,
          componentVersions,
          componentVersionId
        )
      ),
    [componentVersionIds, componentVersions, components]
  );

  return (
    <WizardFormToolbar
      {...props}
      sx={theme => ({
        flexWrap: 'wrap',
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
        justifyContent: 'flex-end'
      })}
    >
      {activeStep > 1 && (
        <Stack
          flexDirection='row'
          justifyItems='flex-start'
          alignItems='center'
          columnGap={1.5}
        >
          <Box>
            {projectType && <BrandImage src={projectType.logo} index={0} />}
          </Box>
          {componentsList?.map((component, index) => (
            <BrandImage
              key={projectType.codeName + component.id}
              src={`/images/${component.codeName.toLowerCase()}.png`}
              index={index + 1}
            />
          ))}
        </Stack>
      )}
    </WizardFormToolbar>
  );
};

const BrandImage = styled('img', {
  shouldForwardProp: prop => prop !== 'index'
})(({ index }) => ({
  width: 24,
  height: 'auto',
  animation: `slideIn ${index * 75}ms 250ms ease-in-out forwards`,
  opacity: 0,
  marginTop: '0 !important',
  '@keyframes slideIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(200%)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  }
}));

ProjectCreate.defaultProps = {
  method: CREATE_METHOD_TYPES.FROM_SCRATCH
};

/**
 *
 * @param {object} props
 * @param {"template"|"scratch"} props.method
 * @returns
 */
export const useProjectCreateSubmit = props => {
  const { method } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const submit = useCallback(
    async data => {
      try {
        if (!data.git?.['@id']) {
          data.git = await dataProvider
            .create('gits', {
              data: data.git
            })
            .then(result => result.data);
        }

        const project = await dataProvider
          .create('projects', {
            data: transformData(data)
          })
          .then(result => result.data);

        if (method === 'scratch') {
          notify('ra.notification.created');
          redirect('edit', 'projects', project.id, project);
        }

        return project;
      } catch (error) {
        notify('ra.notification.http_error', { type: 'error' });
      }
    },
    [dataProvider, method, notify, redirect]
  );

  return submit;
};
