import { Close as CloseIcon } from '@mui/icons-material';
import {
  styled,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Chip,
  chipClasses,
  Button
} from '@mui/material';
import {
  LoadingField,
  SectionHelper,
  SectionTitle
} from '@rc/admin/components';
import React, { useState } from 'react';
import { ReferenceArrayInput, useGetList, useTranslate } from 'react-admin';
import { ComponentSelector } from '..';
import { useWatch } from 'react-hook-form';
import { DEFAULT_SORT } from '@rc/admin/constants';

export const EditComponentsSelector = props => {
  const t = useTranslate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledStack mb={3}>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth={'lg'}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label='close'
            onClick={() => setIsOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pb: 4 }}>
          <SectionTitle>
            {t('resources.projects.sections.components.title')}
          </SectionTitle>
          <SectionHelper sx={{ my: 1 }}>
            {t('resources.projects.sections.components.subtitle')}
          </SectionHelper>
          <ReferenceArrayInput
            reference={'component_versions'}
            source={'componentVersion'}
            label={'Components'}
          >
            <ComponentSelector {...props} itemGridSizes={{ xs: 12, lg: 6 }} />
          </ReferenceArrayInput>
          <Stack alignItems='flex-end' mt={1}>
            <Button variant='contained' onClick={() => setIsOpen(false)}>
              {t('action.done')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack flexDirection='row' justifyContent='space-between' gap={1}>
        <ComponentsList />
        <Button variant='outlined' size='small' onClick={() => setIsOpen(true)}>
          {t('ra.action.edit')}
        </Button>
      </Stack>
    </StyledStack>
  );
};

const ComponentsList = () => {
  const componentVersionIds = useWatch({
    name: 'componentVersion'
  });

  const { data: components, isLoading: isComponentsLoading } = useGetList(
    'components',
    {
      sort: DEFAULT_SORT,
      pagination: {
        page: 1,
        perPage: 1000
      }
    }
  );

  const { data: componentVersions, isLoading: isComponentVersionsLoading } =
    useGetList('component_versions', {
      filter: {
        id: componentVersionIds
      }
    });

  if (isComponentsLoading || isComponentVersionsLoading) {
    return <LoadingField />;
  }

  return (
    <Stack direction='row' flexWrap='wrap' spacing={1}>
      {componentVersions.map(componentVersion => {
        const component = components.find(
          component => componentVersion.component === component.id
        );

        return (
          <Stack
            key={`${component.name}-${componentVersion.version}`}
            gap={0.25}
            alignItems={'center'}
          >
            <Chip
              className={classes.chip}
              avatar={
                <img src={`/images/${component.codeName.toLowerCase()}.png`} />
              }
              label={`${component?.name} ${componentVersion?.version}`}
              variant='filled'
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

const PREFIX = 'EditComponentsSelector';

const classes = {
  chip: `${PREFIX}-chip`
};

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',

  [`& .${classes.chip}`]: {
    padding: theme.spacing(0, 0.25),
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[700]
    },
    [`& .${chipClasses.avatar}`]: {
      width: 'auto',
      height: '75%'
    }
  }
}));
