import React, { useCallback } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  EditClasses,
  SimpleList,
  Datagrid,
  TextField,
  required,
  useNotify,
  useRefresh
} from 'react-admin';
import { useAxios } from '@rc/utils/axios';
import { EditButton } from '@rc/admin/ra';
import { styled, Card, useMediaQuery } from '@mui/material';
import {
  AsideReferenceList,
  Base64UploadInput,
  Breadcrumbs,
  EditTitle
} from '@rc/admin/components';
import { TwoColumnFormStyles } from '@rc/admin/theme/styles';
import { convertFileToBase64 } from '@rc/admin/utils';

export const ProjectTypeEdit = () => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const notify = useNotify();
  const refresh = useRefresh();

  const [, update] = useAxios(
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    {
      manual: true
    }
  );

  const onSubmit = useCallback(
    async submitData => {
      try {
        let data = submitData;
        if (submitData.logo?.rawFile instanceof File) {
          data.logo = await convertFileToBase64(submitData.logo);
        }

        await update({
          url: `${data['@id']}`,
          data
        });

        refresh();
      } catch (error) {
        console.error(error);
        notify(error.message, { type: 'error' });
      }
    },
    [notify, refresh, update]
  );

  return (
    <StyledEdit
      title={<EditTitle />}
      redirect={false}
      actions={<Breadcrumbs />}
      aside={
        <Card className={classes.asideCard}>
          <AsideReferenceList
            resource={'project_type_versions'}
            referenceSource={'projectType'}
            title={'resources.project_types.sections.versions'}
          >
            {isSmall ? (
              <SimpleList
                primaryText={() => <TextField source={'version'} />}
                style={{ width: '100%' }}
              />
            ) : (
              <Datagrid style={{ width: '100%' }}>
                <TextField source={'version'} label='Name' />
                <EditButton />
              </Datagrid>
            )}
          </AsideReferenceList>
        </Card>
      }
    >
      <SimpleForm
        onSubmit={onSubmit}
        defaultValues={{ isEnabled: true }}
        className={classes.form}
      >
        <TextInput source={'name'} fullWidth />
        <Base64UploadInput
          source={'logo'}
          validate={[required()]}
          accept={'image/png,image/svg+xml,image/jpg,image/jpeg'}
          maxSize={150000} // 150kb
        />
      </SimpleForm>
    </StyledEdit>
  );
};

const PREFIX = 'ProjectTypeEdit';

const classes = {
  asideCard: `${PREFIX}-asideCard`,
  form: `${PREFIX}-form`
};

const StyledEdit = styled(Edit)(({ theme }) => ({
  [`& .${EditClasses.main}`]: {
    display: 'grid',
    gridTemplateColumns: '5fr 3fr',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: theme.spacing(3),
      '& > *': {
        width: '100%'
      }
    }
  },

  [`& .${classes.asideCard}`]: {
    height: '100%'
  },

  [`& .${classes.form}`]: {
    ...TwoColumnFormStyles(theme)
  }
}));
