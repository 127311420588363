import { useGetOne } from 'react-admin';
import { useWatch } from 'react-hook-form';

export const useRequiredComponents = () => {
  const id = useWatch({ name: 'projectTypeVersion' });

  const { data: projectTypeVersion, isLoading, isFetching } = useGetOne(
    'project_type_versions',
    { id },
    { enabled: !!id }
  );

  return {
    requiredComponents: projectTypeVersion?.requiredComponent || [],
    defaultComponentVersions: projectTypeVersion?.componentVersions || [],
    isLoading,
    isFetching
  };
};
